import {
  InstrumentAction,
  InstrumentEvent,
  InstrumentStatus,
  InstrumentTagType,
  InstrumentUploaderType,
} from '@finverity/graphql';
import { FinButtonAppearance } from '@finverity/ui-kit';

import { InstrumentApprovalConfigs, InstrumentApprovalRejectedSubscriptionEvents } from '../../../types';
import { InstrumentRejectedDataGridColId } from '../../instrument-rejected-container/constants';

export enum PayerFinanceRequestRejectedFilterIdentifier {
  PayerFinanceRequestRejected = 'PAYER_FINANCE_REQUEST_REJECTED',
  NotFundable = 'NOT_FUNDABLE',
}

export const PAYER_INSTRUMENT_FINANCE_REQUEST_REJECTED_CONFIGS: InstrumentApprovalConfigs = {
  companyType: InstrumentUploaderType.Payer,
  pageTitle: 'Payer Finance Request (Rejected)',
  actionsDefs: [
    {
      disabled: true,
      buttonText: 'Undo Rejection',
      buttonSvgIconName: 'fvIconInstrumentApprovalUndoRejection',
      buttonAppearance: FinButtonAppearance.Primary,
      actionType: InstrumentAction.PayerFinanceUndoRejection,
    },
  ],
  filtersDefs: [
    {
      identifier: PayerFinanceRequestRejectedFilterIdentifier.PayerFinanceRequestRejected,
      label: 'Payer Finance Request (Rejected)',
      params: {
        eventsType: [InstrumentEvent.PayerFinanceCheckerRejected, InstrumentEvent.PayerFinanceMakerRejected],
        statuses: [InstrumentStatus.PayerFinanceRejected],
        tags: {
          notIncludes: [
            InstrumentTagType.Deleted,
            InstrumentTagType.NotFundable,
            InstrumentTagType.NoEligibleFundingWindow,
          ],
        },
      },
      count: 0,
      hiddenColumns: [],
    },
    {
      identifier: PayerFinanceRequestRejectedFilterIdentifier.NotFundable,
      label: 'Not Fundable',
      params: {
        eventsType: [InstrumentEvent.PayerFinanceCheckerRejected, InstrumentEvent.PayerFinanceMakerRejected],
        statuses: [InstrumentStatus.PayerFinanceRejected],
        tags: {
          notIncludes: [InstrumentTagType.Deleted],
          includes: [InstrumentTagType.NotFundable, InstrumentTagType.NoEligibleFundingWindow],
        },
      },
      count: 0,
      hiddenColumns: [
        InstrumentRejectedDataGridColId.DisbursementDate,
        InstrumentRejectedDataGridColId.Tenor,
        InstrumentRejectedDataGridColId.RepaymentDate,
        InstrumentRejectedDataGridColId.AdvanceRate,
        InstrumentRejectedDataGridColId.PrincipalAmount,
        InstrumentRejectedDataGridColId.DisbursementAmount,
        InstrumentRejectedDataGridColId.RepaymentAmount,
        InstrumentRejectedDataGridColId.NetUnfinancedBalance,
        InstrumentRejectedDataGridColId.SellerFeesInAdvance,
        InstrumentRejectedDataGridColId.PayerFeesInAdvance,
        InstrumentRejectedDataGridColId.SellerFeesInArrears,
        InstrumentRejectedDataGridColId.PayerFeesInArrears,
        InstrumentRejectedDataGridColId.TotalSellerFees,
        InstrumentRejectedDataGridColId.TotalPayerFees,
        InstrumentRejectedDataGridColId.TotalFees,
      ],
    },
  ],
  subscriptionEvents: <InstrumentApprovalRejectedSubscriptionEvents>{
    undoRejected: [InstrumentEvent.PayerFinanceMakerPending, InstrumentEvent.FunderApprovalMakerPending],
    updated: [InstrumentEvent.InstrumentEdited],
    removed: [InstrumentEvent.InstrumentDeleted],
  },
  viewUrl: 'payer-finance-request-rejected/view',
  editUrl: 'payer-finance-request-rejected/edit',
};
